import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="https://image.shutterstock.com/image-photo/contact-us-customer-support-inquiry-600w-447727018.jpg"
              alt=""
              height="400px"
              width="900px"
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Contact</h1>
            <p>
              Please contact us by email <a href = "mailto: wchetty2@gmail.com ">Send Email</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
