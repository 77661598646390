import React, { useState } from "react";
import Main from "./Main";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { addToCart } from '../app/counterSlice'
export default function Shop(props) {
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])
   
    useEffect(() => {
        axios.get('https://fakestoreapi.com/products')
            .then((response) => response.data) //2
            .then((data) => {
                setProducts(data)
            });
    })
     
    const onAdd = (product) => { 
        dispatch(addToCart(product))
    };
   
    return (
        <>
            <div className="rowHeader">
                <Main onAdd={onAdd} products={products}></Main>
            </div></>
    );

}