import React from "react";
import { NavLink } from "react-router-dom"; 
import { useSelector } from 'react-redux'

function Navigation() { 
  const cartTotalItems = useSelector((state) => state.counter.cartTotalQuantity)
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            ESCR Store
          </NavLink>
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/shop">
                  Shop Now
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/basket">
                  Cart <mark>{cartTotalItems}</mark>
                </NavLink>
                
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
