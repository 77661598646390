import React from 'react';
import axios from 'axios';

export default class GetRequest extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: []
        };
    }
    componentDidMount(){
        const  { products } = this.state;
        
        axios.get('https://fakestoreapi.com/products')
        .then((response) => response.data) //2
        .then((data) => {
            products.push(data)
        }); 
         
        this.setState({ products });
        console.log(this.state)
      }
    
    render() {
        const { products } = this.state;
        return products
    }
}
