import React, { useState } from 'react'
import 'reactjs-popup/dist/index.css';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Popup from 'reactjs-popup'

export default function Product(props) {
  const { product, onAdd } = props
  const [show, setShow] = useState("true");

  const handleClose = () => setShow("false");
  return (
    <div>
      <img className='small' src={product.image} alt={product.name}></img>
      <h3 className='overflowTextList'>{product.title}</h3>
      <div>R{product.price}</div>
      <div>
        <button className='add' onClick={() => onAdd(product)}>Add To Cart</button>
      </div>
      <br/>
      <Popup trigger={<button> View Details</button>} position="center">
        <Modal.Dialog show={show}>
          <Modal.Header>
            <Modal.Title><strong>{product.title}</strong></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p><strong>Category </strong> : {product.category}</p>
            <p>{product.description}</p>
            <p><strong>Price</strong> R {product.price}</p>
            <p><strong>Rating</strong> {product.rating.rate}/5</p>


          </Modal.Body>

          <Modal.Footer>
            <Button className='add' variant="secondary" onHide={handleClose}>Close</Button>
          </Modal.Footer> 
        </Modal.Dialog>
      </Popup>
    </div>

  )
}
