import React from "react";
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addToCart, decreaseCart } from '../app/counterSlice'

export default function Basket(props) { 
    const dispatch = useDispatch()
    const cartItems = useSelector((state) => state.counter.cartItems)
    const itemsPrice = cartItems.reduce((a ,c) => a + c.price * c.cartQuantity , 0);
    const taxPrice = itemsPrice * 0.15;
    const shippingPrice = itemsPrice > 2000 ? 0 : 100;
    const totalPrice = itemsPrice + shippingPrice + taxPrice;

    const onAdd = (product) => { 
        dispatch(addToCart(product))
    };
    const onRemove = (product) => {
        dispatch(decreaseCart(product))
    }
    return <aside className="blockHeader colHeader-1">
        <h2>Cart Items</h2>
        <div>
            {cartItems.length === 0 && <div>Cart Is Empty </div>}
        </div>
        {cartItems.map((item) => (
            <div key={item.id} className="rowHeader">
                <div className="colHeader-2 overflowText">{item.title} 
                </div>
                <div className="colHeader-2">
                    <button onClick={()=>onAdd(item)} className="plus">+</button> {'     '}
                    <button onClick={()=>onRemove(item)} className="remove">-</button>
                </div>
                <div className="colHeader-2 text-right">
                    {item.cartQuantity} * {item.price.toFixed(2)}
                </div>
            </div>
        ))}

        {cartItems.length !==0 && (
            <>
              <hr/>
              <div className="rowHeader">
                 <div className="colHeader-2">Items Price</div>
                 <div className="colHeader-1 text-right">R{itemsPrice.toFixed(2)}</div>
              </div>
              <div className="rowHeader">
                 <div className="colHeader-2">Tax</div>
                 <div className="colHeader-1 text-right">R{taxPrice.toFixed(2)}</div>
              </div>
              <div className="rowHeader">
                 <div className="colHeader-2">Shipping</div>
                 <div className="colHeader-1 text-right">R{shippingPrice.toFixed(2)}</div>
              </div>
              <div className="rowHeader">
                 <div className="colHeader-2"><strong>Total Cost</strong></div>
                 <div className="colHeader-1 text-right"><strong>R{totalPrice.toFixed(2)}</strong></div>
              </div>
            </>
        )}
    </aside>
}