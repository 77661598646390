import React from "react";
import Product from "./Product";

export default function Main(props) {
  const { products , onAdd } = props
  return <main className="blockHeader colHeader-2">
    <h2>Products</h2>
    <div className="container">
      <div className="rowHeader">
        {products?.map((product) => (
          <div className="card card-1">
            <Product key={product.id} product={product} onAdd={onAdd}></Product>
          </div>
        ))}
      </div>
    </div>
  </main>;
}